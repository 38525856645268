<template>
	<v-container fluid class="mb-5 pb-0">
		<v-row>
			<v-col class="mt-3 mr-5 d-flex justify-end">
				<v-btn
					:disabled="loadingReportAutomation"
					color="textPrimary"
					class="white--text mr-5"
					x-large
					@click="createNewConfiguration()"
				>
					<v-icon :class="hasAuthorityUploadReportAutomation ? 'mr-2' : ''">
						mdi-plus
					</v-icon>
					Nova Configuração
				</v-btn>
			</v-col>
			<v-col cols="12" class="py-0">
				<v-data-table
					id="reportAutomationDataTable"
					class="elevation-1"
					item-key="id"
					:headers="headers"
					:items="reportAutomations"
					:loading="loadingReportAutomation"
					:items-per-page="itemsPerPage"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [25, 50, 75, 100],
						disablePagination: loadingReportAutomation
					}"
					:height="reportAutomations && reportAutomations.length >= 10 ? 528 : null"
				>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:[`item.financialGroupId`]="{ item }">
						{{ (getFinancialGroup(item.financialGroupId).name) }}
					</template>
					<template v-slot:[`item.dataModel`]="{ item }">
						{{ getDataModelType(item.dataModel) || "-" }}
					</template>
					<template v-slot:[`item.reportLayout`]="{ item }">
						{{ getReportLayoutType(item.reportLayout) }}
					</template>
					<template v-slot:[`item.reportQueryType`]="{ item }">
						<!-- {{ item.reportQueryType || "-" }} -->
						Movimentações criadas nos últimos 30 dias por Grupo Financeiro
					</template>
					<template v-slot:[`item.reportIntegrationType`]="{ item }">
						{{ getIntegrationType(item.reportIntegrationType) }}
					</template>
					<template v-slot:[`item.hasError`]="{ item }">
						<div v-if="item.hasError">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on">
										<v-btn
											color="primary"
											icon
											@click="openReportAutomationErrorModal(item, 'movement')"
										>
											<v-icon color="error" size="25"> fas fa-times </v-icon>
										</v-btn>
									</div>
								</template>
								<span> Erro de Movimentação </span>
							</v-tooltip>
						</div>
						<div v-else>
							<v-btn color="primary" icon disabled>
								<v-icon size="25"> fas fa-times </v-icon>
							</v-btn>
						</div>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="textPrimary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getTodoActions(item)" />
            </v-menu>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<SnackbarCustomize ref="SnackbarCustomize" />
	</v-container>
</template>

<script>
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import CustomerReportAutomationService from "@/services-http/sdi/CustomerReportAutomationService.js";
import ReportAutomationMixin from "@/shared/mixins/reportAutomation/reportAutomationMixin";
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import { cloneDeep } from "lodash";
import CustomerReportAutomationStore from '@/store/modules/customer-report-automation/customer-report-automation-module';

export default {
	name: "ReportAutomationTable",

	components: {
		SnackbarCustomize,
		TodoListActions,
	},

	data: () => ({
		loadingReportAutomation: false,
		hasAuthorityUploadReportAutomation: false,
		propsLoadingTableMovementError: false,

		reportAutomationService: null,

		page: 1,
		itemsPerPage: 25,

		propsTabError: '',
		propsInsuredName: '',
		propsMovementError: [],


		reportAutomations: [],
		headers: [
			{
				text: "Grupo Financeiro",
				value: "financialGroupId",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Modelo de Dados",
				value: "dataModel",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Layout de Relatório",
				value: "reportLayout",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Nome de Consulta",
				value: "reportQueryType",
				align: "start",
				class: "text--truncate",
			},
			{
				text: "Tipo de Integração",
				value: "reportIntegrationType",
				align: "start",
				class: "text--truncate ",
			},
			{
				text: "Ações",
				value: "actions",
				align: "start",
				class: "text--truncate",
				sortable: false
			}
		]
	}),

	mixins: [ ReportAutomationMixin, VerifyRoutesMixin ],

	props: {
		financialGroups: {
			type: Array,
			default: () => []
		},
		resetFilterReportAutomation: {
			type: Boolean,
			default: false
		}
	},

	watch: {
		filterReportAutomation: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.$emit("hasFiltersReportAutomation", this.filterReportAutomation)
			}
		},
	},

	methods: {
		createNewConfiguration() {
			this.redirectRouter('CustomerReportAutomationConfiguration',
				{
					isRHProtegido: this.isRHProtegido,
					actionType: 'insert'
				});
		},

		async getReportAutomations () {
			this.loadingReportAutomation = true;
			this.page = 1
			const filteredParams = this.getFilteredParams();
			const params = this.buildSearchParams(filteredParams, "search");
			await this.reportAutomationService.GetReportAutomations(params).then((response) => {
				this.reportAutomations = response.data.content;
			});
			this.loadingReportAutomation = false;
		},

		// searches the financial group presented in the data-table
		getFinancialGroup(financialGroupId) {
			return this.financialGroups.find(el => el.id === financialGroupId) || '-'
		},

		// Assembles and returns the parameters of the filterReportAutomation object
		getFilteredParams() {
			return Object.entries(this.filterReportAutomation)
				.filter(([key, value]) => value && value !== "")
				.reduce((element, [key, value]) => ({ ...element, [key]: value }), {})
		},

		// Assembles and returns the parameters that will be sent in the request
		buildSearchParams(filteredParams, type) {
			const formattedFilterParams = cloneDeep(filteredParams)

			this.deleteEmptyParams(formattedFilterParams)

			return new URLSearchParams({
				...formattedFilterParams,
				isRHProtegido: this.$route.query.isRHProtegido,
				page: this.page - 1,
				size: this.itemsPerPage,
				sort: "id,desc"
			})
		},

		async handlerFilter(value, type) {
			this.page = 1
			this.filterReportAutomation = cloneDeep(value)
			await this.getReportAutomations()
		},

		// Remove parameters with value equal to '', null or undefined
		deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},

		getTodoActions(item) {
      const actions = [
        {
          label: 'Editar',
          icon: 'fas fa-pencil-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.editCustomReport(item),
        },
        {
          label: 'Excluir',
          icon: 'fa fa-trash-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.inactiveCustomReport(item),
        },
      ];

      return actions;
    },

		// edit the selected setting.
		editCustomReport(item) {
			CustomerReportAutomationStore.setFormSelected(item);
			this.redirectRouter('CustomerReportAutomationConfiguration',
			{
				actionType: 'update',
				id: item.id,
				isRHProtegido: this.isRHProtegido,
			});
		},

		// disables the selected setting.
		async inactiveCustomReport(item) {
			item.active = false;
			try {
				await this.reportAutomationService.InactiveForm(item.id);
				this.$refs.SnackbarCustomize.open('success', 'Automação inativada com sucesso.');
			} catch(err) {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao excluir a automação.');
			} finally {
				await this.getReportAutomations();
			}
		},
	},

	created() {
		this.reportAutomationService = new CustomerReportAutomationService();
		this.loadingReportAutomation = true;
		this.isRHProtegido = this.$route.query.isRHProtegido
	},

	async mounted() {
		await this.getReportAutomations();
	},
}
</script>
<style>
	#reportAutomationDataTable .v-data-table-header > tr > th > span {
		text-transform: none !important;
		font-weight: 600;
	}
</style>