import moment from 'moment';

export default {
  data: () => ({
    dates: [],
    dateType: [],
  }),

  methods: {
    processDates(dates, dateType) {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
      this.dateType = dateType;

      this.dates[0] = startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      this.dates[1] = endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    },
    setCommonFilterProperties() {
      return {
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },
  },

  beforeDestroy() {
    this.dates = [];
    this.dateType = [];
  }
}