<template>
	<v-row class="mx-1 d-flex justify-center">
		<v-col class="px-0 pt-0">
			<v-expansion-panels v-model="panelExpanded">
				<v-expansion-panel>
					<v-expansion-panel-header style="cursor: auto" v-if="hasHeader">
						<v-row @click.stop class="d-flex justify-center">
							<v-col>
								<v-icon color="primary" small class="mr-2">
                  fas fa-filter
                </v-icon>
								<span class="primary--text"> {{ headerTitle }} </span>
							</v-col>
							<v-col sm="12" md="8" class="py-2 mr-5 d-flex align-end">
								<DatePickerCustom
									ref="DatePickerCustom"
									:tab="tab"
									:filtersForCheckDate="filtersForCheckDate"
									@handlerClickFilter="$emit('handlerClickFilter', $event)"
									@clearFilters="$emit('clearFilters', $event)"
									@openExpandedContent="$emit('openExpandedContent', $event)"
								/>
							</v-col>
						</v-row>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<slot name="filters"></slot>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-col>
	</v-row>
</template>
<script>
import DatePickerCustom from "@/components/DashboardMovementRecords/Common/DatePickerCustom.vue"
export default {
	name: "CustomFilter",

	components: {
		DatePickerCustom
	},

	data: () => ({}),

	props: {
		panelIsExpanded: null,
		hasHeader: {
			type: Boolean,
			default: true
		},
		filtersForCheckDate: {
			type: Object,
			default: () => {}
		},
    headerTitle: {
      type: String,
      default: "Expandir Opções de Pesquisa"
    },
		tab: null
	},

	computed: {
		panelExpanded: {
			get() {
				return this.panelIsExpanded
			},
			set(newValue) {
				this.$emit("expand", newValue)
			}
		}
	}
}
</script>
